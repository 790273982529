import React from 'react';
import { Carousel } from 'antd';
import { SoundFilled } from '@ant-design/icons';
import Link from 'next/link';

import { Stats } from '~/services/agent';
import Time from '../common/Time';
import { getItemValue } from '~/components/FormItems/Utils';

export default function ReleasedUniversities({ baseColor, rejColor, offerColor, linkOffer }) {
  const { data } = Stats.getReleasedUniversities();
  return (
    <div className="flex items-center md:h-[20px] my-2 px-1">
      <SoundFilled className="text-lg mr-2 text-#FC9F4A" />
      <div className="flex-1">
        <Carousel dotPosition="left" autoplay dots={false} adaptiveHeight>
          {data?.stats?.map(item => {
            const color = item.result === 4 ? rejColor || '#CB4141' : offerColor || '#4FC434';
            return (
              <Link href={`/university/${item.outname.toUpperCase()}`} passHref={linkOffer} key={item.tid}>
                <a className="md:h-[20px] truncate text-xs md:text-sm break-all leading-tight">
                  <div className="space-x-1" style={{ color: baseColor || '#f2f2f2' }}>
                    <span>正在出结果：</span>
                    <span className="font-bold">
                      {item.planmajor}@{item.outname.toUpperCase()}
                    </span>
                    <span>
                      {item.planterm}
                      {item.planyr}
                    </span>
                    <span className="font-bold" style={{ color }}>
                      {getItemValue('result', item.result)}
                    </span>
                    {item.dateline > 0 && (
                      <span className="ml-1 ">
                        <Time fromNow timestamp={item.dateline} />
                      </span>
                    )}
                  </div>
                </a>
              </Link>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
}
